import React from 'react'
import Setup from './tutorial/2-useEffect/setup/3-useEffect-fetch-data'
function App() {
  return (
    <div className='container'>
      <Setup />
    </div>
  )
}

export default App
